
:root{
 --body-background: #202020;
}

body {
 background-color: var(--body-background) !important;
}

ul{
 list-style-type: none;
}

a{
 text-decoration: none;
}

#webpack-dev-server-client-overlay-div {
 display: none !important;
}

#webpack-dev-server-client-overlay-div{
 display: none !important;
}

iframe{
 display: none;
}