.PartnerTitle {
 h1 {
  font-size: 3.4rem;
  color: rgba(248, 94, 17, 1);
 }
}

.partnerContainer{
 margin-top: 12px;
}

.partner{
 justify-content: center;
 align-items: center;
 text-align: center;
}

.imgPartner{
 // object-fit: cover;
 width: 200px;
}