:root {
 --text-color: #fff;
}

.faqBody{
 margin-top: 10rem;
}

.faqBodyDsc{
  width: 100%;
 p{
  font-size: 24px;
  color: var(--text-color);
 }

 .faqHead{
  margin-top: 5px;
  .faqQuestion{
   display: flex;
   justify-content: space-between;
   p {
    font-weight: 600;
    color: var(--text-color);
    font-size: 24px;
  }
  img{
   width: 30px;
   height: 40px;
  }
  }

  .faqAnswer{
   p {
     font-weight: 200;
     color: var(--text-color);
     font-size: 24px;
    }
  }
  // h1{
  //  color: var(--text-color);
  //  font-size: 3.4rem;
  // }
 
 }
}
.pointerimg{
  cursor: pointer;
}

.faqHeadTitle {
 h1 {
  font-size: 3.4rem;
  color: rgba(248, 94, 17, 1);
 }

 p {
  color: var(--text-color);
  font-weight: 600;
  font-size: 24px;
 }
}

.faqimg{
  object-fit: cover;
  width: 100%;
}