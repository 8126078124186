.mediaBody{
 margin-top: 5rem;
 margin-bottom: 5rem;
}

.mediaTitle{
 h1{
  font-size: 3.4rem;
  color: rgba(248, 94, 17, 1);
 }
}
.mediaBox{
  display: flex;
   padding: 18px;
   justify-content: space-around;
  //  background: #FFFFFF 0% 0% no-repeat padding-box;
   border: 2px solid #FFFFFF;
   box-shadow: 6px 6px 11px 10px #0000000D;
}

.mediaImg{
  @media screen and (max-width:580px) {
      width: 50% !important;
      
    }
}

.linkLogo{
 font-size: 80px;
 color: rgba(248, 94, 17, 1);

  @media screen and (max-width:520px) {
    font-size: 40px !important;
  
    }
}

.socialMedia{
  text-align: center;
  padding-top: 50px;
}

.linkText{
 p{
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
 }
}