.footerBody{
padding: 44px 44px;
}

.footerHeading{
 h1{
  font-weight: 700;
  font-size: 3.4rem;
  color: rgba(248, 94, 17, 1);
 }
 p{
  font-size: 24px;
 }
}

.footerBodyLogo{
 padding-top: 6rem;
}

.contactLogo{
 // text-align: center;
 .LogoDetails{
  width: 40px;
  height: auto;
 }
}


.otherDetails{
 margin-top: 6rem;

 .detailLogo {
  margin: auto;
  text-align: center;
  img{
    // width: 100%;
    @media screen and (min-width:1024px) {
      width: 40%;
    }
    @media screen and (max-width:768px) {
      width: 60%;
      
    }
  }
 }
}

.detailsOther{
 padding-top: 40px;
 justify-content: center;
 display: flex;
 li{
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  padding: 10px 10px;

  @media screen and (max-width:280px) {
    font-size: 18px;
  }
}
  @media screen and (max-width:540px) {
    flex-direction: column;
  }

  // @media (max-width: 600px) {
  //   width: 45% ;
  // }
 
// @media (max-width: 420px) {
//   display: contents;
// }
}

.endSection{
 padding-top: 5rem;
 padding-bottom: 5rem;

 hr{
  border: 2px solid #fff;
  background: #fff;
 }
}
.contactTextDetails{
  h3{
    font-size: 2rem;
  }

  p{
    font-size:22px ;
  }
}

.link{
  cursor: pointer;
  color: #fff;
  text-decoration:none ;
}