:root {
 --text-color: #fff;
 --icon-color: rgba(248, 94, 17, 1);
}

.projectbody {
 // height: 90vh;
 margin-top: 10rem;


 .bodydetails {

  .details {
   margin-bottom: 2rem;
  }

  .titleHead {
   margin-bottom: 2em;
   h1{
    font-size: 3.4rem;
    color: rgba(248, 94, 17, 1);
    @media screen and (max-width:425px) {
     font-size:2rem;
    }
   }
  }

  .projectPara{
   p{
    color: var(--text-color);
    font-size: 24px;
   }
  }

  .smallbodyDetails{
   p{
    color: var(--text-color);
    font-size: 24px;
   }
  }

  .percentageIcon {
   h1{
    color: var(--icon-color);
   }
  }

  .imageIcon {
   img {
    width: 30px;
    height: auto;
   }
  }

  .smallHeading {
   p {
    font-weight: 500;
   }
  }
 }

 .detailsImg {
  img {
   object-fit: cover;
   width: 100%;
   max-width: 80%;
  }
 }
}