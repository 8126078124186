:root {
 --text-color: #fff;
}

.servicebody{
 // height: 90vh;
 margin-top: 8rem;


.bodydetails{

 .details{
  margin-bottom: 2rem;
 }

 .titleHead{
  margin-bottom: 2em;
  h1{
   font-size: 3.4rem;
   color: rgba(248, 94, 17, 1);

   @media screen and (max-width:425px) {
    font-size:2rem;
   }
  }
 }

 .servicePara{
  p{
   color: var(--text-color);
   font-size: 24px;
  }
 }


 .smallHeading{
  p{
   color: var(--text-color);
   font-size: 24px;
   font-weight: 500;
  }
 }

 .smallbodyDetails{
  p{
   color: var(--text-color);
   font-size: 24px;
  }
 }
}

 .detailsImg{
  img{
   object-fit: cover;
   width: 100%;
   max-width: 80%;
  }
 }
}