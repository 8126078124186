:root{
  --background-header: #202020;
  --text-color: #FFF
}

.header-wrap {
 position: relative;
 width: 100%;
 background: var(--background-header) !important;
 padding-top: 20px;
 z-index: 1;
 padding-bottom: 20px;
}

.header{
 position: fixed;
 height: 100px;
 width: 100%;
 top: 0;
 left: 0;
 z-index: 1;
 transition: .3s ease-in;
 overflow: hidden;
//  border-bottom: 1px solid #919191;

 a{
  text-decoration: none;
  color: var(--text-color);
 }
}

.header .navbar {
 display: flex;
 background: var(--background-header) !important;
 justify-content: space-between;
 align-items: center;
//  max-width: 1400px;
 margin: auto;
 height: 100%;
 padding: 0 1rem;

@media (max-width: 940px) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}
}

.navoption{
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul{
    margin-bottom: 0px !important;
  }
}

.donate{
  padding: 10px 15px;
  background-image: linear-gradient(#f85e11, #f7ad7f);
  border: none;
  border-radius: 15px;
  color: #fff;
  font-weight: 600;
}

.header .navbar img{
  width: 158px;
  height: auto;
}

// .header .nav-menu {
//  display: flex;
// align-items: center;
// }

.header .nav-item {
 position: relative;
 padding: 1rem;
 font-weight: 600;
 font-size: 22px;
 text-align: center;

 .link{
  cursor: pointer;
 }
}
.header .nav-item::before {
 content: "";
 position: absolute;
 padding: 1rem;
 font-weight: 600;
 border-bottom: 1px solid rgb(0, 0, 0);
 font-size: 22px;
 bottom: -2px;
 width: 100%;
 transform: scale(0, 1);
 transition: transform 0.3s;
 transform-origin: 0 100%;
}

.header .nav-item:hover::before {
  transform: scale(1, 1);
}

.bar {
 display: none;
}

@media (max-width: 940px) {
  .header {
   max-width: 100%;
  }

  .header .navbar {
   max-width: 100%;
  }

  .bar {
   display: block;
   padding: 0px 5px;
  }

  .nav-menu {
   position: fixed;
   left: -100%;
   top: 5rem;
   flex-direction: column;
   background-color: rgba(0, 0, 0, .9);
   width: 100%;
   height: 90vh;
   text-align: center;
   transition: .3s;
   z-index: 999;
  }

  .nav-menu.active{
   left: 0;
  }

  .nav-item {
   margin: 1.5rem 0;
  }
}

// .imgLogo{
//   height: auto;
//   width: 50px;
// }