:root {
  --text-color: #fff;
}

.aboutbody{
 margin-top: 10rem;
}

.titleHead{
  h1{
    font-size: 3.4rem;
    color: rgba(248, 94, 17, 1);
    
    @media screen and (max-width:425px) {
      font-size: 2rem;
    }
  }
}

.aboutPara {
//  font-weight: 500;
 p{
  color: var(--text-color);
  font-size: 26px;
 }
}

.smallbodyDetails{
  p{
    color: var(--text-color);
    font-size: 24px;
  }
}

.claim {
 display: flex;

 button {
  margin-right: 10px;
  padding: 8px 12px 8px 8px;
  background: rgba(248, 94, 17, 1);
 }

 .sharebtn{
  background: rgba(248, 94, 17, 1);
  border: none;
  color: #fff;
  padding: 10px 30px 10px 30px ;
  border-radius: 20px;
 }

 .claimbtn {
  background: none;
  border: 1px solid rgba(248, 94, 17, 1);
  color: rgba(248, 94, 17, 1) ;
  padding: 10px 30px 10px 30px;
  border-radius: 20px;
 }
}

.Imgsection {
 margin-top: 5rem;
 text-align: center;
}

.detailsabout{
//  display: flex;
//  justify-content: center;
 align-items: center;

//  .imageIcon {
//   margin-right: 1rem;
//    img {
//     width: 18px;
//     height: auto;
//    }
//   }
}

.detailsImg {
 img {
  object-fit: cover;
  width: 100%;
  max-width: 80%;
 }
}