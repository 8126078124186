.home{
 margin-bottom: 10rem;

 h1{
  font-size: 3.4rem;
  color: rgba(248, 94, 17, 1);
 }
}
.homeTitle{
  align-self: center;
}

.inputfield{
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  width: 40%;

  @media screen and (max-width:1024px) {
    width: 80%;
  }
  @media screen and (max-width:425px) {
    width: 100%;
  @media (min-width: 760px) {
    width: 85%;
  }
}
}

.homeInput{
text-align: center;
padding-bottom: 20px;
}
.paragraph{
 align-self: center;
}
.para{
 font-weight: 500;
 p{
  font-size: 24px;
 }
}

.claim{
 display: flex;
 justify-content: center;
 button{
  // margin-right: 10px;
  padding: 8px 12px 8px 8px;
 }

 .sharebtn{
  background: black;
  color: #fff;
 }

 .claimbtn{
  background: none;
 }
}

.Imgsection{
  display: flex;
  margin-top: 5rem;
  .arrowsec{
    color: #fff;
    align-self: center;
  }
  .sliderSection{
    width: 100%;
    display: flex;
    gap: 1rem;
    cursor: pointer;
    overflow-x: scroll;
    scroll-behavior: smooth;
    justify-content: center;

    @media screen and (max-width:420px) {
      width: 18rem;
      display: block;
    }
    @media screen and (max-width:280px) {
      width: 18rem;
    }
    @media screen and (max-width:280px) {
      width: 18rem;
    }
  }
    .sliderSection::-webkit-scrollbar{
    display: none;
  }
  

.sliderCard{
  background: #4d4d4d45 !important;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  @media screen and (max-width:720px) {
      padding: 18px 0px 0px 0px;
    }
  @media screen and (max-width:420px) {
      padding: 12px 0px 12px 0px;
      margin-top: 4px;
      width: 250px;
    }
  h2{
    color: #fff;
  }
  p{
    color: #fff;
  }
}
}


.dashboard-logo{
  text-align: center;
  padding-bottom: 20px;

  // @media screen and (max-width: 375px) {
  //   width: 20%;
  // }
}

.dashboard-logo > img{
  width: 100%;

  @media screen and (min-width:1024px) {
    width: 30%;
  }
  @media screen and (min-width:768px) {
    width: 40%;
  }
}