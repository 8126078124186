.teamsPage{
 margin-top: 5rem;
 margin-bottom: 10rem;
}

.scrollBehaviour{
 height: 600px;
 overflow-x: scroll;
 
}

.scrollBehaviour::-webkit-scrollbar {
 display: none;
}

.HeadingTeam{
 text-align: center;
 h2{
  font-weight: 700;
  font-size: 3.2rem;
  color: rgba(248, 94, 17, 1);
 }
 p{
  color: #fff;
  margin-top: 2rem;
  font-size: 24px;
 }
}

.teamImg{
 text-align: center;
 img{
  width: 100px;
  height: auto;
  object-fit: cover;
 }
}

.teamBody{
 margin-top: 4rem;
}

.teamDetails{
 text-align: center;
 color: #fff;
 p:first-child{
  font-size: 22px;
  font-weight: 600;
 }

 p:nth-child(2){
  font-size: 22px;
  // font-weight: 100;
 }
 p:nth-child(3){
  font-size: 22px;
  font-weight: 100;
 }
}

.teamsec{
 padding: 54px 48px;
}

.social{
 text-align: center;
 justify-content: space-between;
 span{
  padding: 10px 10px;
 }
 .icon{
  font-size: 24px;
 }
}